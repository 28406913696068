import React, { useState } from "react";
// Chakra imports
import {
  Box,
  Flex,
  Button,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Link,
  Text,
  useColorModeValue,
  Alert,
  AlertTitle,
  Image,
} from "@chakra-ui/react";
// Assets
import Fetch from "../../components/form/fetch";
import ForgotPasswordModal from "components/Modals/ForgotPassword";
import GoogleIcon from "../../assets/svg/google_login_btn.svg";
import CrossIcon from "../../assets/svg/cross-icon.svg";
import { withRouter } from "react-router-dom";

function SignIn(props) {
  // Chakra color mode

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [correctPassword, setCorrectPassword] = useState(true);

  const handleSubmit = () => {
    const url = "accounts/login/";

    const postData = {
      email: email,
      password: password,
    };

    Fetch(url, postData, { method: "post" }).then((res) => {
      if (res?.status) {
        props.history.push("/admin/catalog");
        const token = res.data.access;
        localStorage.refresh_token = res.data.refresh;
        localStorage.setItem("token", token);
        document.cookie = `token=${token}`;
      } else {
        setCorrectPassword(false);
      }
    });
  };

   const handleKeyPress = (event) => {
     if (event.key === "Enter") {
       handleSubmit();
     }
   };

  const textColor = useColorModeValue("gray.700", "white");
  const bgForm = useColorModeValue("white", "navy.800");
  return (
    <Flex position="relative" mb="0px" ml={"0px"}>
      <Flex
        minH={{ md: "850px" }}
        h={{ sm: "initial", md: "75vh", lg: "85vh" }}
        w="100%"
        maxW="1044px"
        mx="auto"
        justifyContent="space-between"
      >
        <Flex
          w="100%"
          h="100%"
          alignItems="center"
          justifyContent="center"
          // mt={{ base: "50px", md: "20px" }}
        >
          <Flex
            zIndex="2"
            direction="column"
            w="445px"
            background="transparent"
            borderRadius="8px"
            p="30px"
            pt="60px"
            // mx={{ base: "100px" }}
            // m={{ base: "20px", md: "auto" }}
            bg={bgForm}
            boxShadow={useColorModeValue(
              "0px 74px 100px 0px rgba(11, 24, 56, 0.05)",
              "unset"
            )}
          >
            <Text
              fontSize="xl"
              color={textColor}
              fontWeight="bold"
              textAlign="center"
              mb="22px"
              fontStyle={"Source Sans 3"}
            >
              Sign In
            </Text>
            <HStack
              spacing="15px"
              pt={"0.7rem"}
              pb={"1.5rem"}
              justify="center"
              mb="22px"
            >
              <Image src={GoogleIcon} />
            </HStack>
            <Flex direction={"row"} mb={"10px"} justify={"space-between"}>
              <hr width="45%" />
              <Text
                fontSize="lg"
                color="gray.400"
                fontWeight="400"
                textAlign="center"
                mb="10px"
                mt={"-16px"}
              >
                or
              </Text>
              <hr width="45%" ml="80px" />
            </Flex>
            <FormControl>
              <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                Email
              </FormLabel>
              <Input
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                variant="auth"
                fontSize="sm"
                ms="4px"
                type="email"
                placeholder="Enter your email"
                borderRadius={"8px"}
                mb="24px"
                size="md"
                background={"#F8F8F8"}
                onKeyPress={handleKeyPress}
              />
              <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                Password
              </FormLabel>
              <Input
                name="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                variant="auth"
                fontSize="sm"
                ms="4px"
                borderRadius={"8px"}
                type="password"
                placeholder="Your password"
                mb="24px"
                size="md"
                background={"#F8F8F8"}
                onKeyPress={handleKeyPress}
              />
              <Flex justify={"end"}>
                <ForgotPasswordModal />
              </Flex>
              {/* <Text textAlign={"end"} fontSize={"12px"} marginTop={"-15px"}>
                Forgot Password?
              </Text> */}
              {!correctPassword && (
                <Alert status="error" mt={"15px"} borderRadius={"5px"}>
                  <Image src={CrossIcon} w={"20px"} mr={"10px"} />
                  <AlertTitle fontWeight={"normal"}>
                    Invalid username or password
                  </AlertTitle>
                </Alert>
              )}
              <Button
                type="submit"
                fontSize="14px"
                fontWeight="600"
                w="100%"
                borderRadius={"8px"}
                h="52px"
                mt={"24px"}
                onClick={handleSubmit}
                colorScheme="blue"
              >
                SIGN IN
              </Button>
            </FormControl>
            <Flex
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              maxW="100%"
              mt="0px"
            ></Flex>
          </Flex>
        </Flex>
        <Box
          overflowX="hidden"
          h="100%"
          w="100%"
          left="0px"
          position="absolute"
        >
          <Box
            w="100%"
            h="100%"
            bgSize="cover"
            // bg="blue.500"
            opacity="0.8"
          ></Box>
        </Box>
      </Flex>
    </Flex>
  );
}

export default withRouter(SignIn);
