import {
  Avatar,
  Badge,
  Button,
  Flex,
  Td,
  Text,
  Tr,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import DeleteModal from "components/Modals/DeleteModal";
import Fetch from "../../components/form/fetch";
import moment from "moment";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { DeleteIcon } from "@chakra-ui/icons";
import { FaPencilAlt } from "react-icons/fa";

function Catalog(props) {
  const {
    index,
    catalogueData,
    getCatlogueData,
    id,
    created_date,
    data,
    source,
    created_by,
    last_modified_by,
    parent_item,
    isLast,
    sku,
    name,
    last_modified,
    groups,
  } = props;
  const textColor = useColorModeValue("gray.500", "white");
  const titleColor = useColorModeValue("gray.700", "white");
  const color = useColorModeValue("blue.500", "white");
  const btnBorderColor = useColorModeValue("blue.500", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const toast = useToast();
  const history = useHistory();

  const handleUpdateRequest = (id) => {
    history.push({
      pathname: `/admin/catalog/${id}`,
    });
  };

  const handleDeleteRequest = () => {
    Fetch(`dashboard/catalogue/${id}/`, null, { method: "delete" }).then(
      (res) => {
        if (res.status) {
          getCatlogueData();
          toast({
            title: "Catalogue Deleted.",
            description: "We've deleted your catalogue successfully.",
            status: "success",
            duration: 3000,
            isClosable: false,
          });
        }
      }
    );
  };

  return (
    <Tr backgroundColor={index % 2 === 0 ? "" : "rgba(248, 248, 248, 1)"}>
      <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
        <Text fontSize="md" color={textColor} fontWeight="bold" pb=".5rem">
          {id}
        </Text>
      </Td>
      <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
        <Text fontSize="md" color={textColor} fontWeight="bold" pb=".5rem">
          {name}
        </Text>
      </Td>
      <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
        <Text fontSize="md" color={textColor} fontWeight="bold" pb=".5rem">
          {sku}
        </Text>
      </Td>
      <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
        <Text fontSize="md" color={textColor} fontWeight="bold" pb=".5rem">
          {data.slice(0, 20) + "..."}
        </Text>
      </Td>
      <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
        <Text fontSize="md" color={textColor} fontWeight="bold" pb=".5rem">
          {last_modified}
        </Text>
      </Td>
      <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
        <Text fontSize="md" color={textColor} fontWeight="bold" pb=".5rem">
          {groups}
        </Text>
      </Td>

      <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
        {/* <Button m={2} colorScheme="red" onClick={() => handleDeleteRequest(id)}>
          Delete
        </Button> */}
        <Flex>
          <DeleteModal
            name={"Delete"}
            title={"Are you sure you want to delete this catalog?"}
            handleDelete={handleDeleteRequest}
          />
          <Button
            variant="outline"
            color={color}
            borderColor={btnBorderColor}
            colorScheme="blue"
            onClick={() => handleUpdateRequest(id)}
          >
            <FaPencilAlt color={color} />
          </Button>
        </Flex>
      </Td>
    </Tr>
  );
}

export default Catalog;
