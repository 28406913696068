import {
  Badge,
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Select,
  Stack,
  Switch,
  Text,
  Textarea,
  useColorMode,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";

import Fetch from "../../components/form/fetch";
import { FormC } from "../../components/form/validate";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { NavLink } from "react-router-dom/cjs/react-router-dom.min";
import { PlusIco } from "components/Icons/Categories";

const NewCategory = (props) => {
  const toast = useToast();
  const history = useHistory();
  const categoryId = props.match.params?.id;
  const addForm = categoryId === "add";
  const [isLoading, setisLoading] = useState(false);

  const [state, setState] = useState({
    id: "",
    name: "",
    slug: "",
    user: "",
    description: "",
    item_content: "",
  });

  const onSubmit = () => {

    setisLoading(true);
    Fetch(`dashboard/category/${!addForm ? categoryId + "/" : ""}`, state, {
      method: addForm ? "post" : "patch",
    }).then((res) => {
      if (res.status) {
        setisLoading(false);
        toast({
          title: `Category ${addForm ? "created" : "updated"}.`,
          description: `We've ${
            addForm ? "created" : "updated"
          } your category successfully.`,
          status: "success",
          duration: 3000,
          isClosable: false,
        });
        setTimeout(() => {
          history.push({
            pathname: "/admin/categories",
          });
        }, 3000);
      } else {
        setisLoading(false);
      }
    });
  };

  const { handleSubmit, errors, handleNewError } = FormC({
    values: {
      name: state.name,
      description: state.description,
      item_content: state.item_content,
    },
    onSubmit,
  });

  const onChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };
  console.log(errors);
  useEffect(() => {
    if (!addForm) {
      Fetch(`dashboard/category/${categoryId}/`).then((res) => {
        if (res.status) {
          const { data } = res;
          setState({
            id: data.id,
            name: data.name,
            slug: data.slug,
            user: data.user,
            description: data.description,
            item_content: data.item_content,
          });
        }
      });
    }
    // getCategory();
  }, []);

  return (
    <Flex
      direction="column"
      pt={{ base: "120px", md: "75px", lg: "100px" }}
      p={"15px"}
      bgColor={"white"}
    >
      <form onSubmit={handleSubmit}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "50px",
          }}
        >
          <Stack direction="row" width={"fit-content"}>
            <Badge
              height={"28px"}
              width={"110px"}
              fontSize={"16px"}
              textColor={"#898989"}
              lineHeight={"32px"}
              mr={"10px"}
            >
              Categories
            </Badge>
            <Text fontWeight={"bold"} fontSize={"20px"}>
              {!addForm ? state.name : "Add New Item"}
            </Text>
          </Stack>
          <Stack direction="row" spacing={4}>
            <NavLink to="/admin/categories">
              <Button
                variant="outline"
                textColor={"#0584FE"}
                borderColor={"#0584FE"}
              >
                Cancel
              </Button>
            </NavLink>
            <Button isLoading={isLoading} type="submit" colorScheme="blue">
              {!addForm ? "Update" : "Save"} & Close
            </Button>
          </Stack>
        </div>

        <Flex>
          <Text fontWeight={"bold"}>Category Information</Text>
        </Flex>

        <Box
          border="1px"
          borderColor="gray.200"
          p={"15px"}
          mt={"15px"}
          borderRadius={"8px"}
        >
          <Flex
            className="form-container"
            style={{ width: "100%", justifyContent: "space-between" }}
            border={1}
            mt={"10px"}
          >
            <Box width={"70%"}>
              <FormControl
                id="first-name"
                w={"60%"}
                isInvalid={errors?.name?.length}
              >
                <FormLabel fontSize={"16px"} lineHeight={"24px"}>
                  Category Name
                </FormLabel>
                <Input
                  size="md"
                  borderRadius={"8px"}
                  fontSize="sm"
                  placeholder="Enter category name"
                  name="name"
                  value={state.name}
                  onChange={onChange}
                  backgroundColor={"#F8F8F8"}
                  width={"411px"}
                  height={"40px"}
                  border={"1px"}
                  gap={"10px"}
                />
                <FormErrorMessage>{errors?.name}</FormErrorMessage>
              </FormControl>

              <FormControl
                mt={"20px"}
                isInvalid={errors?.data?.length}
                w={"838px"}
              >
                <FormLabel
                  fontWeight={400}
                  fontSize={"16px"}
                  lineHeight={"24px"}
                >
                  Describe what it means if an item is classified in this
                  category
                </FormLabel>
                <Textarea
                  placeholder="Enter data here"
                  value={state.description}
                  name="description"
                  onChange={onChange}
                  minH={"200px"}
                />
                <FormErrorMessage>{errors?.data}</FormErrorMessage>
              </FormControl>
            </Box>

            <Box width={"30%"}>
              <FormControl>
                <FormLabel
                  fontWeight={700}
                  mt={"20px"}
                  fontSize={"16px"}
                  lineHeight={"24px"}
                >
                  Preview impact on an item's content
                </FormLabel>
                <Select
                  placeholder="Select item's content"
                  name="item_content"
                  value={state.item_content}
                  onChange={onChange}
                  size="md"
                  borderRadius={"8px"}
                  fontSize="sm"
                  width={"420px"}
                  height={"40px"}
                  border={"1px"}
                  gap={"10px"}
                >
                  <option value={"Heartland Director's Cut Shiraz 2019"}>
                    Heartland Director's Cut Shiraz 2019
                  </option>
                  <option value={"Heartland Director's Cut Shiraz 2019"}>
                    Heartland Director's Cut Shiraz 2019
                  </option>
                </Select>
              </FormControl>

              {!addForm ? (
                <>
                  <Text mt={"30px"}>Complete item description</Text>
                  <FormControl mt={"20px"}>
                    <Input
                      size="md"
                      name="description"
                      disabled
                      value={state.description}
                      borderRadius={"8px"}
                      placeholder=""
                      height={"60px"}
                      fontSize={"14px"}
                    />
                  </FormControl>
                  <Flex justify={"center"} mt={"10px"}>
                    <PlusIco />
                  </Flex>
                  <FormControl mt={"20px"}>
                    <Input
                      size="md"
                      name="description"
                      disabled
                      value={state.description}
                      borderRadius={"8px"}
                      placeholder=""
                      height={"60px"}
                      fontSize={"14px"}
                    />
                  </FormControl>
                </>
              ) : null}
            </Box>
          </Flex>
        </Box>
      </form>
    </Flex>
  );
};

export default NewCategory;
