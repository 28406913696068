// Chakra imports
import {
  ArrowForwardIcon,
  CheckIcon,
  EmailIcon,
  PhoneIcon,
  SearchIcon,
} from "@chakra-ui/icons";
import {
  Button,
  Flex,
  Icon,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Stack,
  Table,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
// Custom components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import Catalog from "components/Tables/Catalog";
import Fetch from "../../components/form/fetch";
import React, { useEffect, useState } from "react";
import { FaDiceThree, FaFileContract } from "react-icons/fa";
import FilterIcon from "../../assets/svg/filter-icon.svg";
import { ActionsIco } from "components/Icons/menu";
import { PlusIco } from "components/Icons/menu";
import { withRouter } from "react-router-dom";
import { FiltersIco } from "components/Icons/menu";
import { SearchIco } from "components/Icons/Catalog";

function CatalogListing(props) {
  const textColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const [catalogueData, setCatalogueData] = useState([]);
  const [isLoading, setisLoading] = useState(false);
  const toast = useToast();
  const getCatlogueData = () => {
    Fetch("dashboard/catalogue/").then((res) => setCatalogueData(res.data));
  };
  const processEmbed = () => {
    setisLoading(true);
    Fetch("dashboard/process-embeddings", null, { method: "post" }).then(
      (res) => {
        if (res.status) {
          setisLoading(false);
          toast({
            title: `Process Embeddings.`,
            description: `Process embeddings is completed.`,
            status: "success",
            duration: 3000,
            isClosable: false,
          });
        } else {
          setisLoading(false);
        }
      }
    );
  };

  useEffect(() => {
    getCatlogueData();
  }, []);
  return (
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      <Flex p="6px 0px 22px 0px" justify={"space-between"}>
        <Text fontSize="xl" color={textColor} fontWeight="bold">
          Catalog
        </Text>
        <Stack direction={"row"} spacing={4}>
          <InputGroup w={"200px"}>
            <InputRightElement pointerEvents="none">
              {/* <SearchIcon color="gray.300" /> */}
              <SearchIco />
            </InputRightElement>
            <Input
              type="text"
              placeholder="Search"
              textColor={"rgba(177, 177, 177, 1)"}
              fontWeight={400}
              fontSize={"14px"}
              lineHeight={"24px"}
            />
          </InputGroup>

          <Button
            w={"150px"}
            colorScheme="gray"
            color={"gray"}
            fontWeight={"light"}
            variant="outline"
          >
            <Flex justify={"space-between"} minW={"100%"}>
              <Text
                fontSize={"14px"}
                lineHeight={"24px"}
                textColor={"rgba(0, 0, 0, 1)"}
              >
                Filters
              </Text>
              <FiltersIco />
            </Flex>
          </Button>

          <Button
            leftIcon={<PlusIco />}
            onClick={() => props.history.push("/admin/catalog/add")}
            variant="outline"
            width={"120px"}
            h={"40px"}
            borderRadius={"8px"}
            border={"1px solid #0584FE"}
            p={"8px 14px 8px 14px"}
            gap={"10px"}
            fontWeight={"600"}
            fontSize={"14px"}
            lineHeight={"24px"}
            letterSpacing={"1%"}
            textAlign={"center"}
            pl={"15px"}
            textColor={"rgba(5, 132, 254, 1)"}
            borderColor={"rgba(5, 132, 254, 1)"}  
          >
            NEW ITEM
          </Button>
          <Button
            // leftIcon={<PlusIco />}
            isLoading={isLoading}
            onClick={processEmbed}
            variant="outline"
            width={"120px"}
            h={"40px"}
            borderRadius={"8px"}
            border={"1px solid #0584FE"}
            p={"8px 14px 8px 14px"}
            gap={"10px"}
            fontWeight={"600"}
            fontSize={"14px"}
            lineHeight={"24px"}
            letterSpacing={"1%"}
            textAlign={"center"}
            textColor={"rgba(5, 132, 254, 1)"}
            borderColor={"rgba(5, 132, 254, 1)"}
            pl={"15px"}
          >
            Embeddings
          </Button>
          {/* <Button
            rightIcon={<ActionsIco />}
            colorScheme="#0584FE"
            variant="outline"
            width={"120px"}
            height={"40px"}
            borderRadius={"8px"}
            border={"1px solid #0584FE"}
            padding={"8px 14px 8px 14px"}
            gap={"10px"}
            fontWeight={700}
            fontSize={"14px"}
            lineHeight={"24px"}
            letterSpacing={"1%"}
            textAlign={"center"}
            textColor={"#0584FE"}
          >
            ACTIONS
          </Button> */}
        </Stack>
      </Flex>
      <CardBody
        border={"1px solid lightgrey"}
        padding={"15px"}
        borderRadius="5px"
        marginBottom="20px"
        p={0}
      >
        <Table variant="simple" color={textColor}>
          <Thead borderBottom={"1px solid black"}>
            <Tr my=".8rem" pl="0px" color="gray.400">
              <Th borderColor={borderColor} color="gray.400" py={"18px"}>
                DATA ID
              </Th>
              <Th borderColor={borderColor} color="gray.400" py={"18px"}>
                NAME
              </Th>
              <Th borderColor={borderColor} color="gray.400" py={"18px"}>
                SKU
              </Th>
              <Th borderColor={borderColor} color="gray.400" py={"18px"}>
                DATA
              </Th>
              <Th borderColor={borderColor} color="gray.400" py={"18px"}>
                LAST MODIFIED
              </Th>
              <Th borderColor={borderColor} color="gray.400" py={"18px"}>
                GROUPS
              </Th>
              <Th borderColor={borderColor} color="gray.400" py={"18px"}>
                ACTIONS
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {catalogueData?.map((row, index, arr) => {
              return (
                <Catalog
                  index={index}
                  catalogueData={catalogueData}
                  getCatlogueData={getCatlogueData}
                  id={row.id}
                  sku={row.sku}
                  name={row.name}
                  created_date={row.created_date}
                  data={row.data}
                  source={row.source}
                  created_by={
                    row.created_by.first_name + " " + row.created_by.last_name
                  }
                  last_modified_by={row.last_modified_by}
                  parent_item={row.parent_item}
                  // isLast={index === arr.length - 1 ? true : false}
                  isLast={true}
                  key={index}
                  last_modified={row?.last_modified}
                  groups={row?.groups}
                />
              );
            })}
          </Tbody>
        </Table>
      </CardBody>
    </Flex>
  );
}

export default withRouter(CatalogListing);
