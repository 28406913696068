// Chakra imports
import {
  Badge,
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  Select,
  Stack,
  Switch,
  Text,
  Textarea,
  useColorMode,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";

import Fetch from "../../components/form/fetch";
import { FormC } from "../../components/form/validate";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { NavLink } from "react-router-dom/cjs/react-router-dom.min";
import moment from "moment";

function NewCatalog(props) {
  const { colorMode } = useColorMode();
  const history = useHistory();
  const catalogueId = props.match.params?.id;
  const addForm = catalogueId === 'add'
  const [isLoading, setisLoading] = useState(false)
  
  const [state, setState] = useState({
    name: '',
    sku: '',
    data: '',
    content_type: '',
    source: '',
    parent_item: '',
    category: '',
  })
  const toast = useToast()
  const [category, setCategory] = useState([])
  const [showParent,setShowParent] =useState(false)
  
  const onSubmit = () => {
    setisLoading(true)
    Fetch(
      `dashboard/catalogue/${!addForm ? catalogueId + '/' : ''}`,
      state,
      { method: addForm ? "post" : 'patch' }
    ).then((res) => {
      if (res.status) {
        setisLoading(false)
        toast({
          title: `Catalogue ${addForm?'created':'updated'}.`,
          description: `We've ${addForm?'created':'updated'} your catalogue successfully.`,
          status: 'success',
          duration: 3000,
          isClosable: false,
        })
        setTimeout(() => {
          history.push({
            pathname: "/admin/catalog",
          });
        }, 3000)
      }else{
        setisLoading(false)
      }
    });
  };

  const onChange = (e) => {
    if(e.target.name === 'name' && e.target.value > 90) {
      setState(prevState => prevState)
    }
    setState({
      ...state,
      [e.target.name]: e.target.value,
    })
  }

  useEffect(() => {
    if (!addForm) {
      Fetch(`dashboard/catalogue/${catalogueId}/`).then((res) => {
        if(res.status){
          const {data}=res
        setState({
          name: data.name,
          sku: data.sku,
          data: data.data,
          content_type: data.content_type,
          source: data.source,
          parent_item: data.parent_item,
          category: data.category?.id,
          id:data.id,
          last_modified:data.modified_date ? moment(data.modified_date).format('yyyy-MM-DD hh:mm:ss'):null,
          last_modified_by:data.last_modified_by ? moment(data.last_modified_by).format('yyyy-MM-DD hh:mm:ss'):null,
          creation_by:data.created_date ? moment(data.created_date).format('yyyy-MM-DD hh:mm:ss'):null,
        })
      }
      });
    }
    getCategory()
  }, []);
  const getCategory = () => {
    Fetch(`dashboard/category`).then((res) => {
      if (res.status) {
        setCategory(res.data)
      }
    });
  }
  const { handleSubmit, errors, handleNewError, } = FormC({
    values: { name: state.name, sku: state.sku, data: state.data,category: state.category },
    onSubmit,
  });
  return (
    <Flex
      direction="column"
      pt={{ base: "120px", md: "75px", lg: "100px" }}
      p={"15px"}
      bgColor={"white"}
    >
      <form onSubmit={handleSubmit}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "50px",
          }}
        >
          <Stack direction="row" width={"fit-content"}>
            <Badge
              height={"28px"}
              width={"68px"}
              fontSize={"16px"}
              bgColor={"#f8f8f8"}
              textColor={"#898989"}
              lineHeight={"32px"}
              mr={"20px"}
            >
              Catalog
            </Badge>
            <Text fontWeight={"bold"} fontSize={"20px"}>
              {!addForm ? state.name : "Add New Item"}
            </Text>
          </Stack>
          <Stack direction="row" spacing={4}>
            <NavLink to="/admin/catalog">
              <Button
                variant="outline"
                textColor={"#0584FE"}
                borderColor={"#0584FE"}
              >
                Cancel
              </Button>
            </NavLink>
            <Button isLoading={isLoading} type="submit" colorScheme="blue">
              {!addForm ? "Update" : "Save"} & Close
            </Button>
          </Stack>
        </div>

        <Flex>
          <Text fontWeight={"bold"}>General Information</Text>
        </Flex>

        <Box
          border="1px"
          borderColor="gray.200"
          p={"15px"}
          mt={"15px"}
          borderRadius={"8px"}
        >
          <Flex
            className="form-container"
            style={{ width: "100%", justifyContent: "space-between" }}
            border={1}
            mt={"20px"}
            ml={"15px"}
          >
            <Box width={"70%"}>
              <FormControl
                id="first-name"
                w={"60%"}
                isInvalid={errors?.name?.length}
              >
                <FormLabel fontWeight={"bold"}>Item Name</FormLabel>
                <InputGroup>
                  <Input
                    size="md"
                    borderRadius={"8px"}
                    fontSize="sm"
                    placeholder="Enter the item name"
                    name="name"
                    value={state.name}
                    onChange={onChange}
                    backgroundColor={"rgba(222, 222, 222, 0.3)"}
                  />
                  <InputRightElement width="4.5rem">
                    <Button
                      h="1.75rem"
                      size="sm"
                      color={"rgba(137, 137, 137, 1)"}
                      backgroundColor={"rgba(248, 248, 248, 1)"}
                    >
                      {state.name.length} / 90
                    
                    </Button>
                  </InputRightElement>
                </InputGroup>

                <FormErrorMessage>{errors?.name}</FormErrorMessage>
              </FormControl>

              <FormControl w={"60%"} id="country" mt={"30px"}>
                <FormLabel fontWeight={"bold"}>Content Type</FormLabel>
                <Select
                  placeholder="Select the content type"
                  name="content_type"
                  value={state.content_type}
                  onChange={onChange}
                  size="md"
                  borderRadius={"8px"}
                  fontSize="sm"
                >
                  <option value={"Unstructured"}>Unstructured</option>
                  <option value={"Structured"}>Structured</option>
                </Select>
              </FormControl>

              <FormControl w={"60%"} mt={"30px"}>
                <FormLabel fontWeight={"bold"}>
                  Item Generated from Parent
                </FormLabel>
                <Stack align="center" direction="row">
                  <Switch
                    size="md"
                    onChange={() => {
                      setShowParent(!showParent);
                      setState({ ...state, parent_item: "" });
                    }}
                  />
                  <Select
                    size="md"
                    borderRadius={"8px"}
                    name="parent_item"
                    disabled={!showParent}
                    value={state.parent_item}
                    fontSize="sm"
                    placeholder="Select parent item"
                    onChange={onChange}
                  >
                    <option>Unstructured</option>
                    <option>Structured</option>
                  </Select>
                </Stack>
              </FormControl>

              <FormControl w={"60%"} mt={"30px"}>
                <FormLabel fontWeight={"bold"}>Categories</FormLabel>
                <Select
                  size="md"
                  borderRadius={"8px"}
                  name="category"
                  value={state.category}
                  fontSize="sm"
                  placeholder="Select parent item"
                  onChange={onChange}
                >
                  {category?.map((items, key) => (
                    <option key={key} value={items.id}>
                      {items.name}
                    </option>
                  ))}
                </Select>
              </FormControl>
              <FormControl
                w={"60%"}
                mt={"30px"}
                isInvalid={errors?.data?.length}
              >
                <FormLabel fontWeight={"bold"}>SKU</FormLabel>
                <Input
                  size="md"
                  borderRadius={"8px"}
                  fontSize="sm"
                  name="sku"
                  value={state.sku}
                  placeholder="User Input data"
                  onChange={onChange}
                />
                <FormErrorMessage>{errors?.sku}</FormErrorMessage>
              </FormControl>
              <FormControl w={"60%"} mt={"30px"}>
                <FormLabel fontWeight={"bold"} mt={"20px"}>
                  Source
                </FormLabel>
                <Input
                  size="md"
                  name="source"
                  value={state.source}
                  borderRadius={"8px"}
                  fontSize="sm"
                  placeholder="User Input data"
                  onChange={onChange}
                />
              </FormControl>
              <FormControl
                mt={"30px"}
                isInvalid={errors?.data?.length}
                w={"100%"}
              >
                <FormLabel fontWeight={"bold"}>Data</FormLabel>
                <Textarea
                  placeholder="Enter data here"
                  value={state.data}
                  name="data"
                  onChange={onChange}
                  minH={"200px"}
                />
                <FormErrorMessage>{errors?.data}</FormErrorMessage>
              </FormControl>
            </Box>

            {addForm ? null : (
              <Box width={"30%"}>
                <FormControl>
                  <FormLabel fontWeight={"bold"}>Data ID</FormLabel>
                  <Input
                    size="md"
                    borderRadius={"8px"}
                    name="id"
                    disabled
                    value={state.id}
                    fontSize="sm"
                    placeholder="User Input data"
                  />
                </FormControl>
                <FormControl mt={"30px"}>
                  <FormLabel fontWeight={"bold"}>Last modified</FormLabel>
                  <Input
                    size="md"
                    borderRadius={"8px"}
                    disabled
                    fontSize="sm"
                    name="sku"
                    value={state.last_modified}
                    placeholder="User Input data"
                  />
                </FormControl>
                <FormControl mt={"30px"}>
                  <FormLabel fontWeight={"bold"}>Last modified by</FormLabel>
                  <Input
                    size="md"
                    name="source"
                    disabled
                    value={state.last_modified_by}
                    borderRadius={"8px"}
                    fontSize="sm"
                    placeholder="User Input data"
                  />
                </FormControl>
                <FormControl mt={"30px"}>
                  <FormLabel fontWeight={"bold"}>Creation date</FormLabel>
                  <Input
                    size="md"
                    name="source"
                    disabled
                    value={state.creation_by}
                    borderRadius={"8px"}
                    fontSize="sm"
                    placeholder="User Input data"
                  />
                </FormControl>
              </Box>
            )}
          </Flex>
        </Box>
      </form>
    </Flex>
  );
}

export default NewCatalog;
