export const PlusIco = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_587_256950)">
      <rect width="24" height="24" rx="4" fill="#F8F8F8" />
      <g clip-path="url(#clip1_587_256950)">
        <path d="M11 11V5H13V11H19V13H13V19H11V13H5V11H11Z" fill="#B1B1B1" />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_587_256950">
        <rect width="24" height="24" rx="4" fill="white" />
      </clipPath>
      <clipPath id="clip1_587_256950">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
