import {
  Avatar,
  Badge,
  Button,
  Flex,
  Td,
  Text,
  Tr,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import DeleteModal from "components/Modals/DeleteModal";
import Fetch from "../../components/form/fetch";
import moment from "moment";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { DeleteIcon } from "@chakra-ui/icons";
import { FaPencilAlt } from "react-icons/fa";

const Category = (props) => {
  const {
    index,
    id,
    name,
    description,
    items,
    last_modified,
    slug,
    user,
    isLast,
    fetchCategories,
  } = props;

  const toast = useToast();

  const textColor = useColorModeValue("gray.500", "white");
  const titleColor = useColorModeValue("gray.700", "white");
  const color = useColorModeValue("blue.500", "white");
  const btnBorderColor = useColorModeValue("blue.500", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");

  const history = useHistory();

  const handleUpdateRequest = (id) => {
    history.push({
      pathname: `/admin/categories/${id}`,
    });
  };

  const handleDeleteRequest = () => {
    Fetch(`dashboard/category/${id}`, null, { method: "delete" }).then(
      (res) => {
        if (res.status) {
          fetchCategories();
          toast({
            title: "Category Deleted.",
            description: "We've deleted your category successfully.",
            status: "success",
            duration: 3000,
            isClosable: false,
          });
        }
      }
    );
  };

  return (
    <Tr backgroundColor={index % 2 === 0 ? "" : "rgba(248, 248, 248, 1)"}>
      <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
        <Text
          fontSize="14px"
          color={textColor}
          fontWeight="600"
          lineHeight={"19.94px"}
          padding={"10px"}
          textColor={"#FDAD07"}
          backgroundColor={"rgba(244, 192, 6, 0.1)"}
          width={"fit-content"}
          borderRadius={"100px"}
        >
          {name}
        </Text>
      </Td>

      <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
        <Text fontSize="md" color={textColor} fontWeight="bold" pb=".5rem">
          {description.slice(0, 30) + "..."}
          {/* {description} */}
        </Text>
      </Td>

      <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
        <Text fontSize="md" color={textColor} fontWeight="bold" pb=".5rem">
          {items}
        </Text>
      </Td>

      <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
        <Text fontSize="md" color={textColor} fontWeight="bold" pb=".5rem">
          {last_modified}
        </Text>
      </Td>

      <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
        <DeleteModal
          name={"Delete"}
          title={"Are you sure you want to delete this catalog?"}
          handleDelete={handleDeleteRequest}
        />
        <Button
          variant="outline"
          color={color}
          borderColor={btnBorderColor}
          colorScheme="blue"
          onClick={() => handleUpdateRequest(id)}
        >
          <FaPencilAlt color={color} />
        </Button>
      </Td>
    </Tr>
  );
};

export default Category;
