import React, { useEffect, useState } from "react";
// Chakra imports
import {
  Box,
  Flex,
  Button,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Link,
  Text,
  useColorModeValue,
  Alert,
  AlertTitle,
  Image,
  FormErrorMessage,
  toast,
} from "@chakra-ui/react";
// Assets
import Fetch from "../../components/form/fetch";
import { withRouter } from "react-router-dom";
import { FormC } from "components/form/validate";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

function ForgotPassword(props) {
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isValidId, setIsValidId] = useState(false);

  const resetPasswordId = props.match.params?.id;

  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);

  const verifyResetLink = () => {
    Fetch(`accounts/verify-reset-link/${resetPasswordId}/`).then((res) => {
      if (res.status) {
        setIsValidId(true);
      } else {
      }
    });
  };

  useEffect(() => {
    verifyResetLink();
  }, []);

  const onSubmit = () => {
    const url = `accounts/reset/change-password/${resetPasswordId}/`;
    setIsLoading(true);

    const postData = {
      password: newPassword,
      password2: confirmPassword,
    };

    isValidId &&
      Fetch(url, postData, { method: "post" }).then((res) => {
        if (res?.status) {
          setIsLoading(false);
          toast({
            title: `Password updated`,
            description: `Password has been changed successfully`,
            status: "success",
            duration: 3000,
            isClosable: false,
          });
          setTimeout(() => {
            history.push({
              pathname: "/auth/signin",
            });
          }, 3000);
        } else {
          setIsLoading(false);
        }
      });
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSubmit();
    }
  };

  const { handleSubmit, errors, handleNewError } = FormC({
    values: {
      new_password: newPassword,
      confirm_password: confirmPassword,
    },
    onSubmit,
  });

  const textColor = useColorModeValue("gray.700", "white");
  const bgForm = useColorModeValue("white", "navy.800");

  return (
    <Flex position="relative" mb="0px" ml={"0px"}>
      <Flex
        minH={{ md: "850px" }}
        h={{ sm: "initial", md: "75vh", lg: "85vh" }}
        w="100%"
        maxW="1044px"
        mx="auto"
        justifyContent="space-between"
      >
        <Flex
          w="100%"
          h="100%"
          alignItems="center"
          justifyContent="center"
          // mt={{ base: "50px", md: "20px" }}
        >
          <Flex
            zIndex="2"
            direction="column"
            w="445px"
            background="transparent"
            borderRadius="8px"
            p="30px"
            pt="60px"
            // mx={{ base: "100px" }}
            // m={{ base: "20px", md: "auto" }}
            bg={bgForm}
            boxShadow={useColorModeValue(
              "0px 74px 100px 0px rgba(11, 24, 56, 0.05)",
              "unset"
            )}
          >
            <Text
              fontSize="xl"
              color={textColor}
              fontWeight="bold"
              textAlign="center"
              mb="22px"
              fontStyle={"Source Sans 3"}
            >
              Choose a new password
            </Text>

            <Text fontSize={"14px"}>
              To secure your account, choose a strong password you haven’t used
              before and is at least 8 characters long.
            </Text>

            <FormControl mt={"20px"}>
              <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                New Password
              </FormLabel>
              <Input
                name="newPassword"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                variant="auth"
                fontSize="sm"
                ms="4px"
                type="password"
                placeholder="Enter new password"
                borderRadius={"8px"}
                mb="24px"
                size="md"
                background={"#F8F8F8"}
                onKeyPress={handleKeyPress}
              />
              <Text
                mt={"-20px"}
                textColor={"red"}
                fontSize={"13px"}
                ml={"10px"}
              >
                {errors.new_password}
              </Text>
              <FormLabel ms="4px" fontSize="sm" fontWeight="normal" mt={"20px"}>
                Retype new password
              </FormLabel>
              <Input
                name="confirmPassword"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                variant="auth"
                fontSize="sm"
                ms="4px"
                borderRadius={"8px"}
                type="password"
                placeholder="Please confirm your password"
                mb="24px"
                size="md"
                background={"#F8F8F8"}
                onKeyPress={handleKeyPress}
              />
              <Text
                mt={"-20px"}
                textColor={"red"}
                fontSize={"13px"}
                ml={"10px"}
              >
                {errors.confirm_password}
              </Text>
              <Button
                isLoading={isLoading}
                type="submit"
                fontSize="14px"
                fontWeight="600"
                w="100%"
                borderRadius={"8px"}
                h="52px"
                mt={"24px"}
                onClick={handleSubmit}
                colorScheme="blue"
              >
                SUBMIT
              </Button>
            </FormControl>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}

export default withRouter(ForgotPassword);
