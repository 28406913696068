import {
  Alert,
  AlertIcon,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  effect,
  useDisclosure,
} from "@chakra-ui/react";
import Fetch from "components/form/fetch";
import { FormC } from "components/form/validate";
import { useState } from "react";

function ForgotPasswordModal() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [email, setEmail] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = () => {
    setIsLoading(true)
    Fetch(
      "accounts/send-password-reset-link/",
      { email: email },
      { method: "post" }
    ).then((res) => {
      if(res.status) {
          setIsLoading(false)
      } else {
          setIsLoading(false);
      }
    });
  };

  const { handleSubmit, errors, handleNewError } = FormC({
    values: {
      email: email,
    },
    onSubmit,
  });

  return (
    <>
      <Button
        onClick={onOpen}
        alignSelf={"right"}
        border={"none"}
        background={"transparent"}
        fontSize={"12px"}
        height="auto"
        lineHeight={"24px"}
      >
        Forgot Password?
      </Button>

      <Modal onClose={onClose} isOpen={isOpen} isCentered>
        <ModalOverlay />
        <ModalContent borderRadius={"10px"} p={"1rem"}>
          <ModalHeader fontWeight={700} fontSize={"24px"}>
            Forgot Password?
          </ModalHeader>
          <ModalBody>
            <Text pb={"1.5rem"} fontSize={"16px"}>
              Enter your email below to reset your password.
            </Text>
            <FormControl mt={"30px"}>
              <FormLabel>Email</FormLabel>
              <Input
                type="email"
                placeholder="Enter your email"
                borderRadius={"8px"}
                size="md"
                borderColor="#DEDEDE"
                fontSize={"14px"}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <Text textColor={"red"}>{errors?.email}</Text>
              {showAlert && (
                <Alert status="success" mt={"15px"} borderRadius={"8px"}>
                  <AlertIcon />
                  Reset link has been sent to your email
                </Alert>
              )}
            </FormControl>
          </ModalBody>
          <ModalFooter as={"flex"} justifyContent={"space-between"}>
            <Button
              fontSize={"14px"}
              width={"45%"}
              height={"52px"}
              onClick={onClose}
              variant="outline"
              fontWeight={600}
              borderColor="#0584FE"
              color="#0584FE"
              colorScheme="blue"
            >
              CANCEL
            </Button>
            <Button
              isLoading={isLoading}
              fontSize={"12px"}
              height={"52px"}
              width={"45%"}
              colorScheme="blue"
              onClick={() => {
                email.trim() !== "" && setShowAlert(!showAlert);
                handleSubmit();
              }}
            >
              RESET PASSWORD
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default ForgotPasswordModal;
